const AddressHeaderSkeleton = ({ className }: { className?: string }) => {
  return (
    <div
      className={`${className} flex items-center bg-neutral-50 rounded-md text-black mt-1 px-4 w-[27%] py-1 border-2 border-neutral-100 shadow-sm animate-pulse`}>
      <div className="flex items-center justify-center mr-4">
        <div className="bg-gray-300 rounded-full w-8 h-8"></div>
      </div>
      <div className="flex flex-col w-full justify-between">
        <div className="flex items-center justify-between w-full">
          <div className="flex-1">
            <div className="h-3 bg-gray-300 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-5/6"></div>
          </div>
          <div className="ml-4">
            <div className="bg-gray-300 rounded-full w-8 h-8"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressHeaderSkeleton;
