"use client";
import React, { useState } from "react";
import { IconChevronDown, IconChevronUp, IconChevronRight } from "@tabler/icons-react";
import { getCategoriesList2 } from "@lib/data";

interface Category {
  id: string;
  name: string;
  children?: Category[];
}

function CategoryMenu({ categories, dict }: { categories: any[], dict : any }) {
  const [hoveredCategory, setHoveredCategory] = useState<Category | null>(null);
  const [hoveredSubCategory, setHoveredSubCategory] = useState<Category | null>(null);
  const [hoveredChildCategory, setHoveredChildCategory] = useState<Category | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMouseEnterCategory = (category: Category) => {
    setHoveredCategory(category);
    setHoveredSubCategory(null);
    setHoveredChildCategory(null);
  };

  const handleMouseEnterSubCategory = (subCategory: Category) => {
    setHoveredSubCategory(subCategory);
    setHoveredChildCategory(null);
  };

  const handleMouseEnterChildCategory = (childCategory: Category) => {
    setHoveredChildCategory(childCategory);
  };

  const handleMouseLeaveCategory = () => {
    setHoveredCategory(null);
    setHoveredSubCategory(null);
    setHoveredChildCategory(null);
  };

  const renderLevel = (
    items: Category[],
    level: number,
    handleMouseEnter: (item: Category) => void
  ) => (
    <div>
      <ul>
        {items.map((item) => (
          <li
            key={item.id}
            className="relative"
            onMouseEnter={() => handleMouseEnter(item)}
          >
            <span className="block px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center">
              {item.name}
              {item.children && (
                <IconChevronRight className="w-4 h-4 ml-2 text-gray-800" />
              )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );

  const handleButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative">
      <button
        className={`h-12 px-4 py-1 rounded flex items-center justify-between ${
          isMenuOpen ? "bg-red-800 text-white" : "bg-white text-gray-800 hover:bg-gray-100"
        }`}
        onClick={handleButtonClick}
      >
        <span>{dict.nav.browseCategories}</span>
        {isMenuOpen ? (
          <IconChevronUp className="w-4 h-4 ml-2" />
        ) : (
          <IconChevronDown className="w-4 h-4 ml-2" />
        )}
      </button>
      {isMenuOpen && (
        <div className="absolute left-0 mt-2 w-64 bg-white shadow-lg rounded">
          <ul className="p-4">
            {categories.map((category) => (
              <li
                key={category.id}
                className="relative"
                onMouseEnter={() => handleMouseEnterCategory(category)}
                onMouseLeave={handleMouseLeaveCategory}
              >
                <span className="block px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center">
                  {category.name}
                  {category.children && (
                    <IconChevronRight className="w-4 h-4 ml-2 text-gray-800" />
                  )}
                </span>
                {hoveredCategory === category && (
                  <div className="absolute left-full top-0 mt-2 w-[60rem] bg-white shadow-lg rounded">
                    <div className="p-4 grid grid-cols-3 gap-4 space-x-2">
                      {renderLevel(
                        category.children || [],
                        2,
                        handleMouseEnterSubCategory
                      )}
                      {hoveredSubCategory &&
                        renderLevel(
                          hoveredSubCategory.children || [],
                          3,
                          handleMouseEnterChildCategory
                        )}
                      {hoveredChildCategory &&
                        renderLevel(
                          hoveredChildCategory.children || [],
                          4,
                          () => {}
                        )}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CategoryMenu;
