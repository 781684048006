"use client"
import React, { useEffect, useState } from "react"
import AddressHeaderSkeleton from "../../../skeletons/components/skeleton-address"
import { IconMapPinFilled } from "@tabler/icons-react"
import Addresspopup from "@modules/common/components/addresspopup"
import { getSelectedAddress, getUserAddressesAction } from "@/app/actions"
import { usePathname, useRouter } from "next/navigation"

export default function AddressHeader({ className, dict }: { className?: string, dict: any }) {
  const [addresses, setAddresses] = useState<any[]>([])
  const [defaultAddress, setDefaultAddress] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const router = useRouter()
  const { replace } = router
  const pathName = usePathname()

  useEffect(() => {
    async function fetchData() {
      await getUserAddressesAction().then((res: any) => {
        for (let i = 0; i < res.length; i++) {
          res[i].fullAddress = `${res[i].address} ${res[i].streetNumber} - ${res[i].zipCode} ${res[i].city} - ${res[i].province}`
        }
        setAddresses(res)
      }).catch((error) => {
        console.error("Error fetching addresses", error)
      })
    }
    fetchData()
  }, [])

  useEffect(()=>{
    setLoading(false)
  }, [defaultAddress])

  useEffect(() => {
    const getCookieAddress = async () => {
      return await getSelectedAddress()
    }
    getCookieAddress().then((res) => {
      if(res){
        setDefaultAddress(res)
      }else{
        setDefaultAddress(addresses.find((address: any) => address.isDefault) || null)
      }
    })
  }, [addresses])

  const handleChangedAddress = (address: any) => {
    setDefaultAddress(address)
    replace(pathName)
  }

  if (loading) {
    return <AddressHeaderSkeleton className={className} />
  }

  return (
    <div
      className={`${className} flex items-center bg-neutral-50 rounded-md text-black mt-1 px-4 w-[27%] py-1 border-2 border-neutral-100 shadow-sm`}>
      <div className="flex items-center justify-center mr-4">
        <div className="text-red-800">
          <IconMapPinFilled size={32} />
        </div>
      </div>
      <div className="flex flex-col w-full justify-between">
        <div className="flex items-center justify-between">
          {defaultAddress ? (
            <div>
              <span className="font-semibold text-xs line-clamp-1">{dict.addressPopup.deliveryAddress}</span>
              <span className="text-base leading-tight line-clamp-1">
                <strong>{`${defaultAddress.address}${defaultAddress?.streetNumber ? " " + defaultAddress.streetNumber : ""}`}</strong>
              </span>
              <span className="text-base leading-tight line-clamp-1">
              <strong>{`${defaultAddress.zipCode} - ${defaultAddress.city} - ${defaultAddress.province}`}</strong>
              </span>
            </div>
          ) : (
            <span className="text-gray-500 italic">{dict.addressPopup.noDefaultAddress}</span>
          )}
          <Addresspopup savedAddresses={addresses} dict={dict} changedAddress={handleChangedAddress} />
        </div>
      </div>
    </div>
  )

}
