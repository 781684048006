import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent","SheetHeader","SheetTitle"] */ "/app/src/components/ui/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/modules/common/components/category-dropdown/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/modules/common/components/localized-client-link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/app/src/modules/common/components/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/modules/layout/components/addressheader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/modules/layout/components/search-bar/search-bar.tsx");
