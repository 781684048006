"use client"

import { IconArrowRight, IconSearch, IconXboxXFilled } from "@tabler/icons-react"
import React, { useState, useRef, useEffect } from "react"
import { getBarSearchAction } from "@/app/actions"
import { CategoryBar, PartnerBar, ProductBar, SearchBarRes } from "@/types/global"
import Spinner from "@modules/common/icons/spinner"
import Link from "next/link"

const SearchBar = ({dict}) => {
  const [text, setText] = useState("")
  const [debouncedText, setDebouncedText] = useState(text)
  const DEBOUNCE_DELAY = 300;
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState("prodotti")
  const [searchResult, setSearchResult] = useState<SearchBarRes>({
    products: { items: [], totalElements: 0 },
    categories: { items: [], totalElements: 0 },
    partners: { items: [], totalElements: 0 },
  })
  const [loading, setLoading] = useState(false)
  const [counters, setCounters] = useState({ products: 0, categories: 0, partners: 0 })
  const searchBarRef = useRef<HTMLDivElement | null>(null)

  const handleSearch = (newText: string) => {
    setText(newText);
    if (newText.length === 0) {
      setIsModalOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
      setIsModalOpen(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedText(text);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timer);
  }, [text]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const performSearch = async () => {
      if (debouncedText.length > 0) {
        setIsModalOpen(true);
        setLoading(true);

        await getBarSearchAction(debouncedText).then((res) => {
          setSearchResult(res);
          setCounters({
            products: res.products?.totalElements || 0,
            categories: res.categories?.totalElements || 0,
            partners: res.partners?.totalElements || 0
          });
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          setLoading(false);
        });
      }
    };

    performSearch();

  }, [debouncedText]);

  const SkeletonLoader = ({ type }: { type: string }) => {
    return (
      <div className="grid gap-4 p-4">
        {type === "prodotti" && (
          Array(4).fill(0).map((_, index) => (
            <div key={index} className="grid grid-cols-5 pl-5 gap-4 items-center">
              <div className="col-span-1 w-24 h-24 bg-gray-200 rounded-md animate-pulse"></div>
              <div className="col-span-4">
                <div className="w-3/4 h-6 bg-gray-200 rounded-md animate-pulse mb-2"></div>
                <div className="w-1/2 h-4 bg-gray-200 rounded-md animate-pulse"></div>
              </div>
            </div>
          ))
        )}
        {type === "categorie" && (
          Array(5).fill(0).map((_, index) => (
            <div key={index} className="w-full h-6 bg-gray-200 rounded-md animate-pulse mb-2"></div>
          ))
        )}
        {type === "partners" && (
          Array(4).fill(0).map((_, index) => (
            <div key={index} className="w-full h-6 bg-gray-200 rounded-md animate-pulse mb-2"></div>
          ))
        )}
      </div>
    )
  }


  const TabContent = ({ data, type, limit }: { data: any[], type: string, limit: number }) => {

    const productItems: ProductBar[] = type === "prodotti" && data?.length > 0 ? (data).slice(0, limit) : []
    const categoryItems: CategoryBar[] = type === "categorie" && data?.length > 0 ? (data).slice(0, limit) : []
    const partnerItems: PartnerBar[] = type === "partners" && data?.length > 0 ? (data).slice(0, limit) : []

    for (const item of productItems) {
      item.productLink = `/products/${item.producerName}/${item.productName}_${item.productId}`
    }

    for (const item of categoryItems) {
      item.categoryLink = `/products?category=${item.id}`
    }

    for (const item of partnerItems) {
      item.partnerLink = `/partners/${item.prospectTypeName}/${item.name}`
    }

    return (
      <div className="grid grid-cols-1 gap-1 my-2">
        {type === "prodotti" ? productItems?.length > 0 ? productItems.map((item: ProductBar) => (
          <>
            <div className="grid grid-cols-5 gap-4 mx-4 my-3">
              <Link href={item.productLink || "#"}
                 className="col-span-1 w-24 h-24 rounded-md relative overflow-hidden m-auto border-2 border-neutral-100">
                <img
                  onClick={() => setIsModalOpen(false)}
                  className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-110 "
                  src={item.image || "https://edillo.s3.eu-west-2.amazonaws.com/image_placeholder.jpg"}
                  alt={item.productName || "Product Image"}
                />
              </Link>
              <div className="col-span-4 my-1 pb-0">
                <Link
                  href={item.productLink || "#"}
                  onClick={() => setIsModalOpen(false)}
                  className="text-md text-black font-bold rounded-md relative overflow-visible"
                >
                  <p className={" object-cover duration-200 ease-linear transform hover:scale-105 hover:origin-left"}>{item.productName || "-"}</p>
                </Link>
                <div className="text-sm text-black pr-1">
                  {item.shortDescription || "-"}
                </div>
              </div>
            </div>
          </>
        )) : (
          <div className="text-center font-semibold text-gray-500">{dict.searchBar.noResults}</div>
        ) : (
          <></>
        )}

        {type === "categorie" ? categoryItems?.length > 0 ? categoryItems.map((item: CategoryBar) => (
          <>
            <a
              href={item.categoryLink || "#"}
              className="rounded-md relative overflow-visible"
            >
              <div
                className="text-lg pl-6 text-black font-semibold object-cover duration-200 ease-linear transform hover:scale-[1.03] hover:origin-left"
              >
                {item.name || "-"}
              </div>
            </a>
            {categoryItems.indexOf(item) !== categoryItems.length - 1 && <hr className="border-t-1 m-0 border-neutral-200" />}
          </>
        )): (
          <div className="text-center font-semibold text-gray-500">{dict.searchBar.noResults}</div>
        ) : (
          <></>
        )}

        {type === "partners" ? partnerItems?.length > 0 ? partnerItems.map((item: PartnerBar) => (
          <>
            <a
              href={item.partnerLink || "#"}
              className="rounded-md relative overflow-visible"
            >
              <div
                className="text-lg pl-6 text-black font-semibold object-cover duration-200 ease-linear transform hover:scale-[1.03] hover:origin-left"
              >
                {item.name || "-"}
              </div>
            </a>
            {partnerItems.indexOf(item) !== partnerItems.length - 1 && <hr className="border-t-1 border-neutral-200" />}
          </>
        )): (
          <div className="text-center font-semibold text-gray-500">{dict.searchBar.noResults}</div>
        ) : (
          <></>
        )}
      </div>
    )
  }


  const ViewMoreButton = ({ link, size, limit }: { link: string, size: number, limit: number }) => (
    <>
      {size > limit && size > 0 &&
        <div className="flex" style={{ marginTop: "-0.5rem" }}>
          <div className="w-4/5" />
          <button className="w-1/5 flex justify-center p-2 hover:bg-neutral-100 rounded-md"
            onClick={() => setIsModalOpen(false)}>
            <Link href={link} className="text-red-800 font-semibold flex items-center">
              {dict.searchBar.showAll}&nbsp;<IconArrowRight />
            </Link>
          </button>
        </div>
      }
    </>
  )

  return (
    <div ref={searchBarRef} className="relative flex flex-col items-center w-full max-w-3xl">
      <div className="relative w-full">
        <input
          type="text"
          placeholder={dict.searchBar.searchPlaceholder}
          className="w-full pl-10 pr-10 py-3 rounded-full text-gray-800 border border-gray-300 focus:outline-none"
          value={text}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <IconSearch className="absolute left-3 top-3" color="#992315"
          onClick={() => handleSearch(text)}
        />
        <button
          className="absolute right-3 top-3"
          onClick={() => handleSearch("")}
        >
          <IconXboxXFilled color="#9b9797" />
        </button>
      </div>

      {isModalOpen && (
        <div className="absolute top-full mt-2 w-full bg-white border border-gray-300 shadow-lg rounded-lg z-50">
          <div className="flex justify-center space-x-8 p-3">
            <button
              className={`py-2 px-4 rounded-full font-semibold text-sm flex ${
                activeTab === "prodotti" ? "text-white bg-red-800" : "text-gray-500 bg-gray-100"
              }`}
              disabled={loading}
              onClick={() => setActiveTab("prodotti")}
            >
              {dict.searchBar.productsTab}&nbsp;<span className={"m-auto"}>{loading ?
              <Spinner /> : ("(" + counters.products + ")" || 0)}</span>
            </button>
            <button
              className={`py-2 px-4 rounded-full font-semibold text-sm flex ${
                activeTab === "categorie" ? "text-white bg-red-800" : "text-gray-500 bg-gray-100"
              }`}
              disabled={loading}
              onClick={() => setActiveTab("categorie")}
            >
              {dict.searchBar.categoriesTab}&nbsp;<span className={"m-auto"}>{loading ?
              <Spinner /> : ("(" + counters.categories + ")" || 0)}</span>
            </button>
            <button
              className={`py-2 px-4 rounded-full font-semibold text-sm flex ${
                activeTab === "partners" ? "text-white bg-red-800" : "text-gray-500 bg-gray-100"
              }`}
              disabled={loading}
              onClick={() => setActiveTab("partners")}
            >
              {dict.searchBar.partnersTab}&nbsp;<span className={"m-auto"}>{loading ?
              <Spinner /> : ("(" + counters.partners + ")" || 0)}</span>
            </button>
          </div>

          {loading ? (
            <SkeletonLoader type={activeTab} />
          ) : (
            <>
              {activeTab === "prodotti" && (
                <>
                  <TabContent data={searchResult?.products?.items} type="prodotti" limit={4} />
                  <ViewMoreButton
                    link={`/prodotti?page=1&size=20${text ? `&text=${encodeURIComponent(text)}` : ""}`}
                    size={searchResult?.products?.totalElements || 0}
                    limit={4}
                  />
                </>
              )}

              {activeTab === "categorie" && (
                <TabContent data={searchResult?.categories?.items} type="categorie" limit={10} />
              )}

              {activeTab === "partners" && (
                <>
                  <TabContent data={searchResult?.partners?.items} type="partners" limit={10} />
                  <ViewMoreButton link="/partner" size={searchResult?.partners?.totalElements || 0} limit={10} />
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchBar
