"use client"

import React, { useEffect, useState } from "react"
import { useLoadScript } from "@react-google-maps/api"
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { IconArrowForward, IconPlus, IconRotate2 } from "@tabler/icons-react"
import { createUserAddressAction, updateUserAddressAction,getSelectedAddress, storeSelectedAddress } from "@/app/actions"
import { UserAddress } from "@/types/global"

const AddAddressPopup = ({ savedAddresses, dict, changedAddress } : {savedAddresses : any, dict : any, changedAddress: (address: UserAddress) => void }) => {
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(savedAddresses.find((addr) => addr.isDefault) || null)
  const [newAddress, setNewAddress] = useState<UserAddress | null>(null)
  const [addresses, setAddresses] = useState<UserAddress[]>([])
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
    libraries: ["places"],
  })

  useEffect(() => {
    const getCookieAddress = async () => {
      return await getSelectedAddress()
    }

    getCookieAddress().then((res) => {
      if (savedAddresses.length > 0) {
        const defaultAddress = savedAddresses.find((addr) => addr.isDefault)
        setAddresses(savedAddresses)

        if(!res){
          setSelectedAddress(defaultAddress)
          changedAddress(defaultAddress)
        }else{
          setSelectedAddress(res)
          changedAddress(res)
        }
      }
    });
  }, [savedAddresses])

  const handleAddNewAddress = async (address: any) => {
    const [place] = await geocodeByPlaceId(address.value.place_id)
    if (place && place.address_components) {
      const formattedAddress: UserAddress = {
        address: place.address_components.find(c => c.types.includes("route"))?.long_name || "",
        streetNumber: place.address_components.find(c => c.types.includes("street_number"))?.long_name || "",
        city: place.address_components.find(c => c.types.includes("locality"))?.long_name || "",
        zipCode: place.address_components.find(c => c.types.includes("postal_code"))?.long_name || "",
        province: place.address_components.find(c => c.types.includes("administrative_area_level_2"))?.short_name || "",
        country: place.address_components.find(c => c.types.includes("country"))?.short_name || "",
        fullAddress: place.formatted_address,
      }
      setNewAddress(formattedAddress)
    } else {
      console.error(dict.addressPopup.addressNotValid, address)
    }

  }


  const handleSubmit = async () => {
    if (selectedAddress) {
      await storeSelectedAddress(selectedAddress)
      changedAddress(selectedAddress)
      setIsOpen(false)
    }
  }

  const handleSaveNewAddress = (address: UserAddress) => {
    setIsSaving(true)
    createUserAddressAction(address)
      .then(() => {
        setAddresses((prevAddresses) => [...prevAddresses, address])
        setIsAddingNewAddress(false)
        setIsSaving(false)
      })
      .catch((e) => {
        setIsSaving(false)
        console.error(dict.addressPopup.errorTosave, e)
      })
  }

  const handleAddressChange = (address: UserAddress) => {
    setSelectedAddress(address)
  }

  const handleCloseWithoutSaving = () => {
    setIsAddingNewAddress(false)
  }

  if (loadError) {
    return <div>{dict.addressPopup.loadErrorPopUp}</div>
  }

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="link" onClick={()=>{
          setIsOpen(true)
        }} className="text-red-700 hover:underline font-semibold">
          {dict.change}
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent className="max-w-2xl w-full items-center">
        <AlertDialogHeader>
          <AlertDialogTitle>
            {isAddingNewAddress
              ? dict.addressPopup.deliveryAddress
              : (
                <div className="flex justify-between items-center">
                  {dict.addressPopup.savedAddresses}
                  <Button variant="link" className="text-red-800 hover:underline font-semibold ml-auto pr-0"
                          onClick={() => setIsAddingNewAddress(true)}
                  >
                    {dict.addressPopup.addNewAddress}
                  </Button>
                </div>
              )}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <div className={"h-56 content-center overflow-y-auto text-base-regular"}>
          {isAddingNewAddress ? (
            <>
              {isLoaded && (
                <>
                  <GooglePlacesAutocomplete
                    apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
                    apiOptions={{ language: "it", region: "it" }}
                    selectProps={{
                      onChange: (address) => handleAddNewAddress(address),
                      placeholder: dict.addressPopup.searchAddress,
                      loadingMessage: () => dict.addressPopup.loading,
                      noOptionsMessage: () => dict.addressPopup.noResults,
                      className: "w-full rounded-md focus:border-red-800",

                    }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["it"],
                      },
                      types: ["address"],
                    }}
                    debounce={500}
                    onLoadFailed={(error) =>
                      console.error(
                        dict.addressPopup.googleLoadError,
                        error,
                      )
                    }
                  />
                  <div className={"w-full flex justify-between space-x-4"}>
                    <input
                      type="text"
                      hidden={!newAddress}
                      disabled={!newAddress}
                      value={newAddress?.nameReferent || ""}
                      className="w-1/2 mt-4 p-2 border border-gray-300 rounded-md focus:border-red-800"
                      placeholder={dict.addressPopup.nameReferent}
                      onChange={(e) => setNewAddress({ ...newAddress, nameReferent: e.target.value })}
                    />

                    <input
                      type="text"
                      hidden={!newAddress}
                      disabled={!newAddress}
                      value={newAddress?.phoneReferent || ""}
                      onChange={(e) => setNewAddress({ ...newAddress, phoneReferent: e.target.value })}
                      className="w-1/2 mt-4 p-2 border border-gray-300 rounded-md focus:border-red-800"
                      placeholder={dict.addressPopup.phoneReferent}
                    />

                  </div>

                </>
              )}
            </>
          ) : (
            <>
              <RadioGroup
                value={selectedAddress?.fullAddress || ""}
                defaultValue={selectedAddress?.fullAddress}
              >
                {addresses.map((address, index) => (
                  <div className="flex items-center mb-2 w-full" key={index}>
                  <RadioGroupItem
                      value={address.address!}
                      id={`address-${index}`}
                      checked={address.id === selectedAddress?.id}
                      onClick={() => handleAddressChange(address)}
                    />
                    <Label
                      className="flex flex-1 items-center text-md text-gray-700 font-semibold ml-2 cursor-pointer"
                      htmlFor={`address-${index}`}
                    >
                      <span className="flex-1">{address.fullAddress}</span>
                    </Label>
                  </div>

                ))}
              </RadioGroup>
            </>
          )}
        </div>
        <AlertDialogFooter className="flex mt-auto justify-between">
          {!isAddingNewAddress ? (
            <>
              <AlertDialogCancel onClick={()=>{setIsOpen(false)}}>{dict.cancel}</AlertDialogCancel>
              <AlertDialogAction onClick={handleSubmit}>{dict.confirm}</AlertDialogAction>
            </>
          ) : (
            <>
              <Button
                onClick={() => handleCloseWithoutSaving()}
                className="mt-4 pr-4"
                variant="secondary"
              >
                <IconRotate2 />
                &nbsp;{dict.back}
              </Button>
              <Button
                disabled={!newAddress}
                onClick={() => handleSaveNewAddress(newAddress!)}
                className="mt-4"
                isLoading={isSaving}
              >
                {dict.add}
              </Button>
            </>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>

    </AlertDialog>
  )
}


export default AddAddressPopup
